import type React from 'react';
import classNames from 'classnames';
import styles from './BadgeGroup.module.scss';

type BadgeGroupProps = {
    children: React.ReactNode;
};

const BadgeGroup: React.FC<BadgeGroupProps> = ({ children }) => (

    <div className={classNames(
        styles.BadgeGroup,
    )} >{children}</div>
);

export default BadgeGroup;
