import type React from 'react';
import classNames from 'classnames';
import styles from './Badge.module.scss';

type BadgeProps = {
    children: React.ReactNode;
    colorScheme?: 'transparent' | 'red' | 'pink-lighter';
    customClassNames?: string;
    hideDesktop?: boolean;
    hideMobile?: boolean;
    onImage?: boolean;
    onImagePos?: 'top' | 'bottom';
    size?: 'sm';
};

const Badge: React.FC<BadgeProps> = ({
    children,
    colorScheme = 'red',
    customClassNames,
    hideDesktop = false,
    hideMobile = false,
    onImage = false,
    onImagePos = 'top',
    size,
}) => (
    <span className={classNames(
        styles.Badge,
        customClassNames,
        { [styles.Badge_small as string]: size === 'sm' },
        { [styles.Badge__color_red as string]: colorScheme === 'red' },
        { [styles.Badge__color_transparent as string]: colorScheme === 'transparent' },
        { [styles.Badge__color_pink_lighter as string]: colorScheme === 'pink-lighter' },
        { [styles.Badge__inline as string]: !onImage },
        { [styles.Badge__image_top as string]: onImage && onImagePos === 'top' },
        { [styles.Badge__image_bottom as string]: onImage && onImagePos === 'bottom' },
        { 'show-lg': hideMobile },
        { 'hide-lg': hideDesktop },
    )} >{children}</span>
);

export default Badge;
