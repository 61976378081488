import type React from 'react';
import className from 'classnames';
import styles from './CoolerBadge.module.scss';
import Translation from '../Translation/Translation';
import Icon from '../../../stories/Atoms/Icon/Icon';

type CoolerBadgeProps = {
    hideDesktop?: boolean;
    hideMobile?: boolean;
    isFree?: boolean;
    location?: 'imageGallery' | 'productBox';
};

const CoolerBadge: React.FC<CoolerBadgeProps> = ({
    hideDesktop = false,
    hideMobile = false,
    isFree = false,
    location = 'productBox',

}) => {
    const notificationFree = <Translation pageString='productinformatie' stringId='temperature_notification_cooler_free_badge' />;
    const notificationPaid = <Translation pageString='productinformatie' stringId='temperature_notification_cooler_paid_badge' />;
    return (
        <span
            className={ className(
                styles.CoolerBadge,
                { [styles.CoolerBadge__color_blue as string]: true },
                { 'show-lg': hideMobile },
                { 'hide-lg': hideDesktop },
                { [styles.CoolerBadge__image_imageGallery as string]: location === 'imageGallery' },
                { [styles.CoolerBadge__image_productBox as string]: location === 'productBox' },
            )
            }
            data-testid="cooler-badge"
        ><Icon name={ 'fa-badge-check'} /><p>{isFree ? notificationFree : notificationPaid}</p></span>
    );
};

export default CoolerBadge;
