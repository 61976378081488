import React, { useRef, useContext } from 'react';
import Translation from '../Translation/Translation';
import Picture from '../Picture/Picture';
import type { Price } from '../Format/FormatPrice';
import FormatPrice from '../Format/FormatPrice';
import { useWithSiteUrl } from '../WebshopContext/WebshopContext';
import Badge from '../Badge/Badge';
import Source from '../Picture/Source';
import CoolerBadge from '../CoolerBadge/CoolerBadge';
import { WebshopContext } from '../WebshopContext/WebshopContextProvider';
import BadgeGroup from '../BadgeGroup/BadgeGroup';

interface ProductBoxProps {
    alt?: string;
    amount?: number;
    editableButton?: React.ReactElement;
    hasFreeCoolerNotification: boolean;
    href:string;
    image: string;
    isHorizontal?: boolean;
    lowestPrice?: Price;
    onProductClicked?: () => boolean;
    price: Price;
    priceFrom: Price;
    productId?: number;
    title: string;
}

const ProductBox: React.FC<ProductBoxProps> = ({
    alt,
    amount,
    editableButton,
    hasFreeCoolerNotification,
    href,
    image,
    isHorizontal,
    lowestPrice,
    onProductClicked,
    price,
    priceFrom,
    productId,
    title,
}) => {
    const isDiscount = price.value < priceFrom.value;
    const withSiteUrl = useWithSiteUrl();

    const ref = useRef<HTMLDivElement>(null);
    const button = React.cloneElement(editableButton ?? <>{}</>, { positionRef: ref, productId });
    const { hasActiveCoolerNotification } = useContext(WebshopContext);

    return (
        <div className="product-box is-col-1" ref={ref}>
            {button}
            <a
                href={withSiteUrl(href)}
                title={title}
                onClick={onProductClicked}
            >
                <div className="product-box__image">
                    {isHorizontal && (
                        <Picture imageUrl={image} alt={alt || title} draggable={false}>
                            <Source imageWidth={100} />
                        </Picture>
                    )}
                    {!isHorizontal && (
                        <Picture imageUrl={image} alt={alt || title} draggable={false}>
                            <Source imageWidth={322} minScreenWidth={576}/>
                            <Source imageWidth={199} />
                        </Picture>
                    )}
                    <BadgeGroup>
                        {(hasActiveCoolerNotification && hasFreeCoolerNotification) && (
                            <CoolerBadge isFree location={'productBox'}/>
                        )}
                        {isDiscount && (
                            <Badge onImage customClassNames="bold u-text--u u-text--xxs">
                                <Translation pageString="artikel_icon" stringId="sale" />
                            </Badge>
                        )}
                    </BadgeGroup>

                    { (isDiscount && !isHorizontal) && <Badge hideDesktop onImage onImagePos="bottom" customClassNames="u-text--xxs" colorScheme="pink-lighter">
                        <Translation pageString="artikel_icon" stringId="save" />
                        {' '}
                        { `${Math.round(((priceFrom.value - price.value) / priceFrom.value) * 100)}%`}
                    </Badge>
                    }

                </div>
                <div className="product-box__info">
                    <span className="product-box__info-title">
                        { title }
                    </span>
                    <div className="product-box__info-price">
                        { lowestPrice && !isDiscount && (
                            <>
                                <Translation pageString="artikel_icon" stringId="vanaf" />
                                {' '}
                                <FormatPrice price={lowestPrice} />
                            </>
                        )}
                        { isDiscount && (
                            <>
                                <span className='product-box__info-price-discount'>
                                    <FormatPrice price={priceFrom} />
                                </span>
                                <span className='mr-2'>
                                    <FormatPrice price={price} />
                                </span>
                                <Badge hideMobile={!isHorizontal} customClassNames="u-text--xxs" colorScheme="pink-lighter">
                                    <Translation pageString="artikel_icon" stringId="save" />
                                    {' '}
                                    { `${Math.round(((priceFrom.value - price.value) / priceFrom.value) * 100)}%`}
                                </Badge>
                            </>
                        )}
                        {(!lowestPrice && !isDiscount) && <FormatPrice price={price} />}
                    </div>
                    {!!amount && amount > 1 && (
                        <div className="product-box__info-types">
                            {amount}
                            {' '}
                            <Translation pageString="overview_page" stringId="types" />
                        </div>
                    )}
                </div>
            </a>
        </div>
    );
};

export const MemoProductBox = React.memo(ProductBox);

export default ProductBox;
